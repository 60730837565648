<template>
	<div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
		<h1>Ошибка</h1>
		<p class="text-center"><span v-html="$route.params.message || message"></span></p>
	</div>
</template>

<script>
  export default {
    name: "Error",
    props: ['message'],
  }
</script>

<style scoped>

</style>