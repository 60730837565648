var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loading',{attrs:{"active":_vm.$store.state.isLoading,"can-cancel":false,"is-full-page":true,"loader":"dots","background-color":"#000","opacity":0.2,"color":"#0d6efd"}}),(!_vm.$store.state.isLoading)?_c('div',[(_vm.$store.getters.items.length > 0)?_c('div',[_c('swiper',{class:_vm.swiperClass,attrs:{"pagination":{type: 'progressbar'},"navigation":_vm.$store.getters.items.length > 1,"allowTouchMove":_vm.allowTouchMove},on:{"slideChange":_vm.onSlideChange}},_vm._l((_vm.$store.getters.items),function(item){return _c('swiper-slide',{key:item.id,staticClass:"px-5"},[_c('item',{attrs:{"id":item.id}})],1)}),1)],1):_c('div',[_c('error',{attrs:{"message":"Ничего не найдено!"}})],1),(_vm.$store.state.currentItemId)?_c('div',{class:{
          'modal': true,
          'fade': true,
          'text-dark': true,
          'show': _vm.$store.state.isShowModal,
          'mustShow': _vm.$store.state.isShowModal,
        },attrs:{"tabindex":"-1"}},[_c('item-modal',{attrs:{"id":_vm.$store.state.currentItemId}})],1):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }