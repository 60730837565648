const Helper = {
  urls: [
    'https://mssg.su/api/public/tunnelSessions',
    'https://console.bot-marketing.com/api/public/tunnelSessions',
  ],
  //url: 'https://mssg.su/api/public/tunnelSessions',
  sessionName: 'WebappSession',
  errorMessage: 'Сессия завершена!',
};

module.exports = Helper;