<template>
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click.prevent="$store.commit('closeModal')"></button>
      </div>
      <div class="modal-body">
        <h5 class="mt-2">Текст</h5>
        <textarea class="form-control" rows="15" v-model="answer"></textarea>

        <div v-if="item.otherCategory">
          <h5 class="mt-2">Категория</h5>
          <input type="text" class="form-control"v-model="category">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click.prevent="$store.commit('closeModal')">Закрыть</button>
        <button type="button" class="btn btn-info" @click.prevent="add" v-if="item.otherCategory ">Добавить и отправить</button>
        <button type="button" class="btn btn-primary" @click.prevent="submit">Отправить</button>
      </div>
    </div>
  </div>
</template>

<script>
import ItemExtends from '../extends/item';

export default {
  name: "TaskModal",
  mixins: [ItemExtends],
  data() {
    return {
      answer: '',
      category: '',
    }
  },
  methods: {
    submit: function () {
      this.send({'answer': this.answer, 'otherCategory': this.category});
      this.$store.commit('closeModal');
    },
    add: function () {
      this.$store.dispatch('add', {'answer': this.answer, 'otherCategory': this.category, id: this.id});
      this.$store.commit('closeModal');
    }
  },
  created() {
    this.answer = this.item.answerChatGpt;
    this.category = this.item.otherCategory;
  }
}
</script>

<style scoped>

</style>