<template>
	<div>
		<loading :active="$store.state.isLoading"
		         :can-cancel="false"
		         :is-full-page="true"
		         loader="dots"
		         background-color="#000"
		         :opacity="0.2"
		         color="#0d6efd"
		/>

		<div v-if="!$store.state.isLoading">
      <div v-if="$store.getters.items.length > 0">
        <swiper
            :class="swiperClass"
            :pagination="{type: 'progressbar'}"
            :navigation="$store.getters.items.length > 1"
            :allowTouchMove="allowTouchMove"
            @slideChange="onSlideChange"
        >
          <swiper-slide v-for="item in $store.getters.items" :key="item.id" class="px-5">
            <item :id="item.id"></item>
          </swiper-slide>
        </swiper>
      </div>
      <div v-else><error message="Ничего не найдено!"></error></div>

			<div :class="{
          'modal': true,
          'fade': true,
          'text-dark': true,
          'show': $store.state.isShowModal,
          'mustShow': $store.state.isShowModal,
        }"
        tabindex="-1"
        v-if="$store.state.currentItemId"
      >
        <item-modal :id="$store.state.currentItemId"></item-modal>
			</div>
		</div>
	</div>
</template>

<script>
  import Item from "@/components/Item";
  import ItemModal from "@/components/ItemModal";
  import Error from "@/components/Error";
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import {Navigation, Pagination} from 'swiper'
  import {Swiper, SwiperCore, SwiperSlide} from 'swiper-vue2'
  import 'swiper/swiper-bundle.css'
  import Helper from '../helper'

  SwiperCore.use([Navigation, Pagination]);

  export default {
    name: "Index",
    components: {Error, Swiper, SwiperSlide, Loading, Item, ItemModal},
    data() {
      return {
        Helper: Helper,
        swiperIndex: 0,
      }
    },
    computed: {
      allowTouchMove: function(){
        if (this.$store.getters.items.length <= 1) {
          return false;
        }
        return !this.showModal;
      },
      currentItem: function () {
        return this.$store.getters.items[this.swiperIndex];
      },
      swiperClass: function () {
        if (this.currentItem.status == 'send') {
          return ['bg', 'bg-green'];
        }
        else if (this.currentItem?.otherCategory) {
          return ['bg', 'bg-grey'];
        }
        return ['bg', 'bg-yellow'];
      }
    },
    methods: {
      onSlideChange (e) {
        this.swiperIndex = e.activeIndex;
      }
    },
    async mounted() {
      if (this.$route.name != 'error') {
        const session = this.$route.params.session ?? this.$cookies.get(Helper.sessionName);

        if (session) {
          this.$store.commit('setSession', session);
          this.$store.commit('setTunnelSessionsUrl', this.$route.params?.tunnelSessionsUrl);
          this.$cookies.set(Helper.sessionName, session);

          this.$store.commit('loading', true);

          this.$store.dispatch('load')
              .then(() => {
                this.$store.commit('loading', false);

                if (['active'].includes(this.$store.state.sessionStatus) === false) {
                  this.$router.push({ name: 'error', params: {message: Helper.errorMessage} });
                }
              })
        }
        else {
          this.$router.push({ name: 'error', params: {message: Helper.errorMessage} });
        }
      }
    }
  }
</script>

<style scoped>
.bg {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
}
.bg-yellow {
  background-image: url("../images/Rectangle-14.png");
}
.bg-green {
  background-image: url("../images/Rectangle-13.png");
}
.bg-grey {
  background-image: url("../images/Rectangle-15.png");
}
</style>