import Helper from '../helper'
import moment from 'moment';
import 'moment/locale/ru';

export default {
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      Helper: Helper,
      moment: moment,
    }
  },
  methods: {
    send: function (params) {
      this.$store.dispatch('send', {...params, id: this.id});
    },
    strLimit: function (text, limit = 100) {
      if(text.length > limit) {
        return text.substring(0, limit) + ' ...'
      }
      return text;
    }
  },
  computed: {
    item: function () {
      return this.$store.getters.item(this.id);
    },
    date: function () {
      const m = moment(this.item.msgTime);
      if (m.isValid()) {
        return m.format('DD.MM.YYYY HH:mm');
      }

      return  this.item.msgTime;
    }
  }
}