<template>
	<div class="d-flex flex-column h-100 min-vh-100">
		<div class="flex-shrink-0 top">

      <div>
        <span :class="['badge', 'me-2', 'rounded-pill', 'bg-secondary']">{{ item.status }}</span>
        <span >{{ date }}</span>
      </div>
      <p>{{ item.name }}</p>
      <p><a :href="item.chat" target="_blank">Чат в chat2desk</a></p>
      <h5>Текст клиента</h5>
      <p v-if="item.msgUser.length > limit">
        {{ strLimit(item.msgUser, !isFullShowUserText ? limit : 99999999) }}
        <br>
        <a href="#" @click.prevent="isFullShowUserText = !isFullShowUserText">Показать/свернуть</a>
      </p>
      <p v-else>{{ strLimit(item.msgUser, limit) }}</p>

      <h5>Текст отправки</h5>
      <p v-if="item.answerChatGpt.length > limit">
        {{ strLimit(item.answerChatGpt, !isFullShowGptText ? limit : 99999999) }}
        <br>
        <a href="#" @click.prevent="isFullShowGptText = !isFullShowGptText">Показать/свернуть</a>
      </p>
      <p v-else>{{ strLimit(item.answerChatGpt, limit) }}</p>
    </div>
		<div class="mt-auto pb-5 mb-5 text-center" v-if="item.status != 'send'">
			<button class="btn btn-secondary text-decoration-none me-1" @click.prevent="$store.commit('showModal', id)">
				Изменить
			</button>
      <button class="btn btn-secondary text-decoration-none" @click.prevent="send({'answer': item.answerChatGpt, 'otherCategory': item.otherCategory})">
        Отправить
      </button>
		</div>
	</div>
</template>

<script>
  import ItemExtends from '../extends/item';
  export default {
    name: "Task",
    mixins: [ItemExtends],
    data() {
      return {
        limit: 200,
        isFullShowUserText: false,
        isFullShowGptText: false,
      }
    }
  }
</script>

<style scoped>
  .top {
    margin-top: 150px;
  }

</style>