import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
import Vuex from 'vuex'
const axios = require('axios').default;
const qs = require('qs');

import "bootstrap-icons/font/bootstrap-icons.css";
import 'bootstrap/dist/css/bootstrap.css'
import './css/style.css'

Vue.use(Vuex)
Vue.use(VueRouter);
Vue.use(VueCookies);
Vue.config.productionTip = false;
Vue.$cookies.config('7d');

import App from './App.vue'
import Index from '@/components/Index'
import Error from '@/components/Error'
import Helper from './helper'

const routes = [
  { path: '/session/:session/:tunnelSessionsUrl?', component: Index, name: 'index', alias: '/' },
  { path: '/error', component: Error, name: 'error' },
  { path: '*', component: Index }
];
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  },
});
const store = new Vuex.Store({
  state: {
    session: '',
    tunnelSessionsUrl: '',
    sessionStatus: '',
    isLoading: true,
    items: [],
    currentItemId: null,
    isShowModal: false
  },
  mutations: {
    setSession(state, session) {
      state.session = session;
    },
    setTunnelSessionsUrl(state, tunnelSessionsUrl) {
      state.tunnelSessionsUrl = tunnelSessionsUrl;
    },
    loading(state, v) {
      state.isLoading = v;
    },
    updateItems(state, variables) {
      state.items = variables?.items ?? [];
    },
    showModal(state, id) {
      state.isShowModal = true;
      state.currentItemId = id;
    },
    closeModal(state) {
      state.isShowModal = false;
      state.currentItemId = null;
    }
  },
  getters: {
    item: (state) => (id) => {
      return state.items.find(item => item.id == id);
    },
    items: (state) => {
      return state.items;
    },
    url: (state) => {
      return Helper.urls[state.tunnelSessionsUrl] ?? Helper.urls[0];
    }
  },
  actions: {
    async load ({ commit, state, dispatch, getters }) {
      try {
        const response = await axios.get( getters.url + '/' + state.session);
        state.sessionStatus = response?.data?.statusName;
        state.sprint = response?.data?.variables?.sprint ?? {};

        commit('updateItems', response?.data?.variables);
      } catch (error) {
        console.error(error);
      }
    },

    async send({state, dispatch, getters }, params) {
      await axios.post(getters.url + '/' + state.session + '/request',
        qs.stringify({'code': 'send', 'params': params})
      );
      dispatch('load');
    },

    async add({state, dispatch, getters}, params) {
      await axios.post(getters.url + '/' + state.session + '/request',
        qs.stringify({'code': 'add', 'params': params})
      );
      dispatch('load');
    },
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')